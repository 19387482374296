<template>
  <v-card class="d-flex flex-column mw-100" style="height: 350px; width: 300px;">
    <v-card-title class="font-weight-semibold">
      {{ title }}
    </v-card-title>
    <div class="content-container">
      <half-circle-spinner v-if="isLoading" :animation-duration="1000" :size="70" :color="'white'"
        class="text-center" />
      <apexchart v-else-if="series.length" type="donut" :width="width" :options="chartOptions" :series="series">
      </apexchart>
      <div v-else>
        <h1>0</h1>
        <h3>tickets</h3>
      </div>
    </div>
    <v-card-text class="font-weight-semibold text-center text-primary mt-auto">
      {{ subtitle }}
    </v-card-text>
  </v-card>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners';
import VueApexCharts from 'vue-apexcharts';

export default {
  props: {
    width: {
      type: Number,
      default: 350
    },
    series: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    apexchart: VueApexCharts,
    HalfCircleSpinner

  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#8c57ff',
            shadeTo: '',
            shadeIntensity: 0.7
          }
        },
        labels: this.labels,
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => a + b);
                  }
                },
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                }
              }
            }
          }
        }
      }
    };
  },
  watch: {
    labels(newLabels) {
      this.chartOptions.labels = newLabels;
    }
  }
};
</script>
<style scoped>
.content-container {
  display: flex;
  flex-grow: 1;
  justify-content: center !important;
  align-items: center;
  text-align: center;
}

h1 {
  font-size: 5rem !important;
}
</style>